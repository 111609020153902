import {
  addCompetitionComponentAccessMutation,
  addCompetitorCustomersMutation,
  addCompetitorMutation,
  createCompetitionComponentMutation,
  removeCompetitionComponentAccessMutation,
  removeCompetitorCustomersMutation,
  updateCompetitionComponentMutation,
  updateCompetitionComponentVisibilityMutation,
  updateCompetitorMutation,
} from '../graphql/mutations';
import {
  getCompetitionComponentQuery,
  getCompetitorsQuery,
  subscribeToCompetitionComponent,
} from '../graphql/queries';
import { apolloInstance } from '../vue-apollo';

export const getCompetitionComponent = ({ type, companyId }) => {
  return apolloInstance.query({
    query: getCompetitionComponentQuery,
    variables: { type, companyId },
    fetchPolicy: 'no-cache',
  });
};

export const subscribeToCompetition = ({ type, companyId }) => {
  return apolloInstance.subscribe({
    query: subscribeToCompetitionComponent,
    variables: { type, companyId },
  });
};

export const createCompetitionComponent = (data) => {
  return apolloInstance.mutate({
    mutation: createCompetitionComponentMutation,
    variables: { object: data },
  });
};

export const updateCompetitionComponent = ({ type, companyId, set }) => {
  return apolloInstance.mutate({
    mutation: updateCompetitionComponentMutation,
    variables: { type, companyId, set },
  });
};

export const addCompetitionComponentAccess = (data) => {
  return apolloInstance.mutate({
    mutation: addCompetitionComponentAccessMutation,
    variables: { object: data },
  });
};

export const removeCompetitionComponentAccess = (id) => {
  return apolloInstance.mutate({
    mutation: removeCompetitionComponentAccessMutation,
    variables: { id },
  });
};

export const updateCompetitionComponentVisibility = ({
  addVisibility,
  removedVisibilities,
}) => {
  return apolloInstance.mutate({
    mutation: updateCompetitionComponentVisibilityMutation,
    variables: { addVisibility, removedVisibilities },
  });
};

export const getCompetitors = (companyId) => {
  return apolloInstance.query({
    query: getCompetitorsQuery,
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });
};

export const addCompetitor = (data) => {
  return apolloInstance.mutate({
    mutation: addCompetitorMutation,
    variables: { object: data },
  });
};

export const updateCompetitor = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateCompetitorMutation,
    variables: { id, set },
  });
};

export const addCompetitorCustomers = (data) => {
  return apolloInstance.mutate({
    mutation: addCompetitorCustomersMutation,
    variables: { objects: data },
  });
};

export const removeCompetitorCustomers = (ids) => {
  return apolloInstance.mutate({
    mutation: removeCompetitorCustomersMutation,
    variables: { ids },
  });
};
