import {
  addChannelMutation,
  addCustomerDetailMutation,
  addCustomerMutation,
  addKeyResourceMutation,
  createBusinessModelByTypeMutation,
  deleteChannelMutation,
  deleteKeyResourceMutation,
  updateBusinessModelMutation,
  updateChannelMutation,
  updateCustomerDetailMutation,
  updateCustomerMutation,
  updateKeyResourceMutation,
  addKeyActivityMutation,
  updateKeyActivityMutation,
  deleteKeyActivityMutation,
  addKeyPartnerMutation,
  updateKeyPartnerMutation,
  deleteKeyPartnerMutation,
  addCostMutation,
  updateCostMutation,
  deleteCostMutation,
  updateMultipleCostsMutation,
  addRevenueMutation,
  deleteRevenueMutation,
  addRevenueCustomerMutation,
  updateRevenueMutation,
  addBusinessModelAccessMutation,
  removeBusinessModelAccessMutation,
  updateBusinessModelVisibilityMutation,
  removeRevenueCustomerMutation,
  deleteCustomerDetailMutation,
} from '../graphql/mutations/business-model';
import {
  businessModelByTypeQuery,
  getAllChannelsQuery,
  getChannelsQuery,
  getCostsQuery,
  getCustomersQuery,
  getKeyActivitiesQuery,
  getKeyPartnersQuery,
  getKeyResourcesQuery,
  getRevenueQuery,
  subscribeToBusinessModelTypeQuery,
} from '../graphql/queries/business-model';
import { apolloInstance } from '../vue-apollo';

export const getBusinessModelByType = ({ type, companyId }) => {
  return apolloInstance.query({
    query: businessModelByTypeQuery,
    variables: { type, companyId },
    fetchPolicy: 'no-cache',
  });
};

export const subscribeToBusinessModelType = ({ type, companyId }) => {
  return apolloInstance.subscribe({
    query: subscribeToBusinessModelTypeQuery,
    variables: { type, companyId },
  });
};

export const createBusinessModelByType = (data) => {
  return apolloInstance.mutate({
    mutation: createBusinessModelByTypeMutation,
    variables: { object: data },
  });
};

export const updateBusinessModel = ({ type, companyId, set }) => {
  return apolloInstance.mutate({
    mutation: updateBusinessModelMutation,
    variables: { type, companyId, set },
  });
};

export const addBusinessModelAccess = (data) => {
  return apolloInstance.mutate({
    mutation: addBusinessModelAccessMutation,
    variables: { object: data },
  });
};

export const removeBusinessModelAccess = (id) => {
  return apolloInstance.mutate({
    mutation: removeBusinessModelAccessMutation,
    variables: { id },
  });
};

export const updateBusinessModelVisibility = ({
  addVisibility,
  removedVisibilities,
}) => {
  return apolloInstance.mutate({
    mutation: updateBusinessModelVisibilityMutation,
    variables: { addVisibility, removedVisibilities },
  });
};

export const getCustomers = (companyId) => {
  return apolloInstance.query({
    query: getCustomersQuery,
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });
};

export const addCustomer = (data) => {
  return apolloInstance.mutate({
    mutation: addCustomerMutation,
    variables: { object: data },
  });
};

export const updateCustomer = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateCustomerMutation,
    variables: { id, set },
  });
};

export const addCustomerDetail = (data) => {
  return apolloInstance.mutate({
    mutation: addCustomerDetailMutation,
    variables: { object: data },
  });
};

export const updateCustomerDetail = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateCustomerDetailMutation,
    variables: { id, set },
  });
};

export const deleteCustomerDetail = (id) => {
  return apolloInstance.mutate({
    mutation: deleteCustomerDetailMutation,
    variables: { id },
  });
};

export const getChannels = ({ businessModelId, customerId, topic }) => {
  return apolloInstance.query({
    query: getChannelsQuery,
    variables: { businessModelId, customerId, topic },
    fetchPolicy: 'no-cache',
  });
};

export const getAllChannels = ({ businessModelId, topic }) => {
  return apolloInstance.query({
    query: getAllChannelsQuery,
    variables: { businessModelId, topic },
    fetchPolicy: 'no-cache',
  });
};

export const addChannel = (data) => {
  return apolloInstance.mutate({
    mutation: addChannelMutation,
    variables: { object: data },
  });
};

export const updateChannel = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateChannelMutation,
    variables: { id, set },
  });
};

export const deleteChannel = (id) => {
  return apolloInstance.mutate({
    mutation: deleteChannelMutation,
    variables: { id },
  });
};

export const getKeyResources = (businessModelId) => {
  return apolloInstance.query({
    query: getKeyResourcesQuery,
    variables: { businessModelId },
    fetchPolicy: 'no-cache',
  });
};

export const addKeyResource = (data) => {
  return apolloInstance.mutate({
    mutation: addKeyResourceMutation,
    variables: { object: data },
  });
};

export const updateKeyResource = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateKeyResourceMutation,
    variables: { id, set },
  });
};

export const deleteKeyResource = (id) => {
  return apolloInstance.mutate({
    mutation: deleteKeyResourceMutation,
    variables: { id },
  });
};

export const getKeyActivities = (businessModelId) => {
  return apolloInstance.query({
    query: getKeyActivitiesQuery,
    variables: { businessModelId },
    fetchPolicy: 'no-cache',
  });
};

export const addKeyActivities = (data) => {
  return apolloInstance.mutate({
    mutation: addKeyActivityMutation,
    variables: { object: data },
  });
};

export const updateKeyActivities = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateKeyActivityMutation,
    variables: { id, set },
  });
};

export const deleteKeyActivities = (id) => {
  return apolloInstance.mutate({
    mutation: deleteKeyActivityMutation,
    variables: { id },
  });
};

export const getKeyPartners = (businessModelId) => {
  return apolloInstance.query({
    query: getKeyPartnersQuery,
    variables: { businessModelId },
    fetchPolicy: 'no-cache',
  });
};

export const addKeyPartners = (data) => {
  return apolloInstance.mutate({
    mutation: addKeyPartnerMutation,
    variables: { object: data },
  });
};

export const updateKeyPartners = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateKeyPartnerMutation,
    variables: { id, set },
  });
};

export const deleteKeyPartners = (id) => {
  return apolloInstance.mutate({
    mutation: deleteKeyPartnerMutation,
    variables: { id },
  });
};

export const getCosts = (businessModelId) => {
  return apolloInstance.query({
    query: getCostsQuery,
    variables: { businessModelId },
    fetchPolicy: 'no-cache',
  });
};

export const addCost = (data) => {
  return apolloInstance.mutate({
    mutation: addCostMutation,
    variables: { object: data },
  });
};

export const updateCost = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateCostMutation,
    variables: { id, set },
  });
};

export const deleteCost = (id) => {
  return apolloInstance.mutate({
    mutation: deleteCostMutation,
    variables: { id },
  });
};

export const updateMultipleCosts = ({ ids, set }) => {
  return apolloInstance.mutate({
    mutation: updateMultipleCostsMutation,
    variables: { ids, set },
  });
};

export const addRevenue = (data) => {
  return apolloInstance.mutate({
    mutation: addRevenueMutation,
    variables: { object: data },
  });
};

export const updateRevenue = ({ id, set }) => {
  return apolloInstance.mutate({
    mutation: updateRevenueMutation,
    variables: { id, set },
  });
};

export const deleteRevenue = (id) => {
  return apolloInstance.mutate({
    mutation: deleteRevenueMutation,
    variables: { id },
  });
};

export const getRevenueSources = (businessModelId) => {
  return apolloInstance.query({
    query: getRevenueQuery,
    variables: { businessModelId },
    fetchPolicy: 'no-cache',
  });
};

export const addRevenueCustomer = (data) => {
  return apolloInstance.mutate({
    mutation: addRevenueCustomerMutation,
    variables: { object: data },
  });
};

export const removeRevenueCustomer = ({ revenueId, customerId }) => {
  return apolloInstance.mutate({
    mutation: removeRevenueCustomerMutation,
    variables: { revenueId, customerId },
  });
};
