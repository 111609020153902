import Vue from 'vue';

const Base = Vue.options.components['v-step'];

const TourStep = Base.extend({
  methods: {
    enableScrolling() {
      if (this.params.enableScrolling) {
        if (this.step.offset) {
          const appContent = document.querySelector('.app__content');
          const top = this.targetElement.offsetTop;
          appContent.scrollTo({
            left: 0,
            top: top + this.step.offset,
            behavior: 'smooth',
          });
        } else {
          this.targetElement.scrollIntoView({
            behavior: 'smooth',
            block: this.params.scrollTop || 'start',
          });
        }
      }
    },
  },
});

export default TourStep;
